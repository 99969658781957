import React from 'react';
import './App.css';
import { LoaderProvider } from './common-shared/loader-provider/LoaderProvider'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Landing } from './pages/landing/landing';
import { Login } from './pages/login/login';
import { CreateFrame } from './pages/create-frame/create-frame';
import { Checkout } from './pages/checkout/checkout';
import { NotFound } from './pages/not-found/not-found';
import { Layout } from './common-implemented/layout/layout';

export const App: React.FC = () => {
  return (
    <LoaderProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="create-frame" element={<CreateFrame />} />
            <Route path="login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LoaderProvider>
  );
}
