import React from "react";
import './coming-soon.scss';

export const ComingSoon: React.FC = () => {
    return (
        <section className="coming-soon">
            <div className="coming-soon-figure">
                <div className="main-content-coming-soon">
                    <img className="qr-code" src="assets/images/demos/framingo-demo-logo.jpg" alt="framingo-whatapp-business-qr-code"/>
                    <h1>Bring Your Memories to Life with Framingo</h1>
                    <p>While we’re busy perfecting our website, you don’t have to wait! Scan the QR code to place your order on WhatsApp. Choose your favorite photos and frame color, and we’ll handle the rest.</p>
                    <p className="italic-msg">Framingo – simple, beautiful, and just a message away.</p>
                    <img className="qr-code image-filter-revert" src="assets/images/demos/framingo-whatapp-qr.PNG" alt="framingo-whatapp-business-qr-code"/>
                </div>
            </div>
        </section>
    )
}