import React from "react";
import './bg-scroll-frames.scss';

export const BgScrollFrames: React.FC<{
    readonly img?: string
}> = ({ img }) => {
    return (
        <>
            <div className="frame-body">
                <div className="border">
                    <div className="frame">
                        <img className="image" src={img} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
}

