import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";

export const Layout: React.FC = () => {
    return (
        <>
            <Header/>
            <Outlet />
            <Footer />
        </>
    )
}
