import React from "react";

export const Login: React.FC = () => {
    return (
        <>
            login
        </>
    )
}

