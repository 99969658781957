import React from "react";
import './landing.scss';
import { BgScrollFrames } from "../../common-implemented/bg-scroll-frames/bg-scroll-frames";
import { ComingSoon } from "../../common-implemented/coming-soon/coming-soon";
export const Landing: React.FC = () => {
    
    const images = [
        'assets/images/demos/demo-1.jpg',
        'assets/images/demos/demo-2.jpg',
        'assets/images/demos/demo-3.jpg',
        'assets/images/demos/demo-4.jpg',
        'assets/images/demos/demo-5.jpg',
        'assets/images/demos/demo-6.jpg',
        'assets/images/demos/demo-7.jpg',
        'assets/images/demos/demo-8.jpg',
        'assets/images/demos/demo-9.jpg',
        'assets/images/demos/demo-10.jpg',
        'assets/images/demos/demo-3.jpg',
        'assets/images/demos/demo-4.jpg',
        'assets/images/demos/demo-6.jpg',
        'assets/images/demos/demo-7.jpg',
        'assets/images/demos/demo-8.jpg',
        'assets/images/demos/demo-7.jpg',
        'assets/images/demos/demo-8.jpg',
        'assets/images/demos/demo-9.jpg',
        'assets/images/demos/demo-4.jpg',
        'assets/images/demos/demo-6.jpg',
        'assets/images/demos/demo-7.jpg',
        'assets/images/demos/demo-1.jpg',
        'assets/images/demos/demo-2.jpg',
        'assets/images/demos/demo-3.jpg',
        'assets/images/demos/demo-4.jpg',
        
    ];

    return (
        <section className="landing-page">
            <div className="coming-soon-popup">
                <ComingSoon/>
            </div>
            <div className="archivements">
                <div className="archivements-row">
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-top">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archivements-row">
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <BgScrollFrames  key={i} img={img} />
                        ))}
                    </div>
                    <div className="archivements-down">
                        {images.map((img, i) => (
                            <div className="frame-item">
                                <BgScrollFrames  key={i} img={img} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

